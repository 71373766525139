/* istanbul ignore file */
import React from 'react'

import ReactDOM from 'react-dom/client'

import Antecipacao from '@interco/front-ib-antecipacao-recebiveis'

import './styles/global.scss'

const App = () => (
  <Antecipacao
    accounts={[]}
    clientType="PJ"
    appType="PJ"
    env={process.env.NODE_ENV}
    runtimeEnv={process.env.NODE_ENV}
    handleNavigation={() => {
      throw new Error('Function not implemented.')
    }}
    routes={{}}
  />
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
